<template>
  <div>
    <h1>Photo Uploader</h1>
    <PhotoUploader
      :update="photosUpdated"
      accept="image/png, image/jpeg, image/jpg, application/pdf"
    />
  </div>
</template>

<script>
import PhotoUploader from '@/components/Fields/PhotoUploader'

export default {
  components: {
    PhotoUploader
  },
  methods: {
    photosUpdated() {
      console.log('photosUpdated')
    }
  }
}
</script>
